<template>
  <section class="text-white">
    <div class="flex items-center justify-between border-blue-50 pb-6 md:border-b md:pb-10">
      <div class="flex flex-col gap-2.5">
        <div class="m-0 text-xl font-bold text-white md:text-2xl">
          {{ props.header }}
        </div>
        <HtmlParser
          class="text-base font-normal"
          :html="props.subheader"
        />
      </div>
      <div>
        <UiButton
          class="hidden md:block"
          @click="toggleAppAccount"
        >
          <div class="flex items-center justify-center gap-2">
            {{ t('cms_log_in_now') }}
            <UiIcon
              :height="14"
              name="arrow"
            />
          </div>
        </UiButton>
      </div>
    </div>

    <div class="flex flex-wrap items-stretch justify-between gap-x-4 gap-y-8 md:pt-7">
      <template
        v-for="box in props.boxes"
        :key="box.uid"
      >
        <div
          class="flex w-fit items-center gap-5 md:gap-9"
        >
          <UiIcon
            class="shrink-0"
            :width="72"
            :height="72"
            :name="box.icon"
          />
          <div class="text-base font-normal">
            <div class="font-bold">
              {{ box.header }}
            </div>
            <HtmlParser
              :html="box.description"
            />
          </div>
        </div>

        <div class="hidden h-auto w-px bg-blue-50 lg:block [&:nth-child(3n)]:hidden" />
      </template>
    </div>

    <UiButton
      class="mt-8 block w-full md:hidden"
      @click="toggleAppAccount"
    >
      <div class="flex items-center justify-center gap-2">
        {{ t('cms_log_in_now') }}
        <UiIcon
          :height="14"
          name="arrow"
        />
      </div>
    </UiButton>
  </section>
</template>

<script setup lang="ts">
import { useDrawer } from '@base/components/AppDrawer/useDrawer'
import type { T3CeRawlplugLoginBoxProps } from './T3CeRawlplugLoginBox.types'

const props = defineProps<T3CeRawlplugLoginBoxProps>()

const { toggleAppAccount } = useDrawer()
const { t } = useI18n()
</script>
